@import 'tr_design_tokens';
//@import 'design_colors';  comes from tokens

$margin-small: 5px;
$margin-med: 8px;
$margin-large: 10px;

$padding-small: 5px;
$padding-med: 8px;
$padding-large: 10px;

$font-small: 0.8rem;
$font-smaller: 0.9rem;
$font-std: 1rem;
$font-bit-larger: 1.1rem;
$font-larger: 1.2rem;
$font-large: 1.4rem;
$font-huge: 1.7rem;

$card-bg: $ds-surface;
$card-border-radius: 4px;

// Match Trello label items, radius
$label-border-radus: 3px;
$section-border-radius: 4px;

$menu-bg-color: $ds-surface-sunken;

$tr-mat-menu-item-bg: $Neutral200A;
$tr-mat-menu-item-bg-hover: $Neutral300A;

// See: https://github.com/trello/trellicolors/blob/master/build/brand-colors.scss

$trello-badge-background-none: $ds-background-neutral;
$trello-badge-color-none: $ds-text;

$trello-badge-background-green: $ds-background-accent-green-subtler;
$trello-badge-color-green: $ds-text-accent-green-bolder;
$trello-badge-background-yellow: $ds-background-accent-yellow-subtler;
$trello-badge-color-yellow: $ds-text-accent-yellow-bolder;
$trello-badge-background-orange: $ds-background-accent-orange-subtler;
$trello-badge-color-orange: $ds-text-accent-orange-bolder;
$trello-badge-background-red: $ds-background-accent-red-subtler;
$trello-badge-color-red: $ds-text-accent-red-bolder;
$trello-badge-background-purple: $ds-background-accent-purple-subtler;
$trello-badge-color-purple: $ds-text-accent-purple-bolder;
$trello-badge-background-blue: $ds-background-accent-blue-subtler;
$trello-badge-color-blue: $ds-text-accent-blue-bolder;
$trello-badge-background-pink: $ds-background-accent-magenta-subtler;
$trello-badge-color-pink: $ds-text-accent-magenta-bolder;
$trello-badge-background-sky: $ds-background-accent-teal-subtler;
$trello-badge-color-sky: $ds-text-accent-teal-bolder;
$trello-badge-background-lime: $tr-background-accent-lime-subtler;
$trello-badge-color-lime: $tr-text-accent-lime-bolder;
$trello-badge-background-light-gray: $ds-background-accent-gray-subtler;
$trello-badge-color-light-gray: $ds-text-accent-gray-bolder;

$hover-opacity: 0.8;

// --- Editor Settings --- //

// max height of multiline editor / text area
$multiline_max_height: 400px;

// ---- Reusable Mixins --------------- //

/**
 * Commmon styling used when we need to show something that looks like
 * an Trello badge icon.
 */
@mixin trello-badge-icon {
   // .icon-md, .icon-sm
   webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   display: inline-block;
   font-family: trellicons;
   font-style: normal;
   font-weight: 400;
   line-height: 1;
   text-align: center;
   text-decoration: none;
   vertical-align: bottom;

   // .icon-sm
   height: 20px;
   font-size: 16px;
   line-height: 20px;
   width: 20px;

   // icon-md.mod-quiet
   color: $ds-text-subtlest; //#6b778c;

   // Mirror the plugin-icon.icon-sm style
   background-size: 14px;
   background-repeat: no-repeat;
   background-position: 3px 3px;
   vertical-align: bottom;

   // plugion-icon.icon-sm.mod-quiet
   filter: brightness(0) invert(46%) sepia(9%) saturate(928%) hue-rotate(180deg) brightness(97%)
      contrast(86%);
}
