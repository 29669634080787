$spacing-steps: 10;
$spacing-increment: 4px;

// Spacing based off the concept of vuetify spacing helpers

@for $i from 0 through $spacing-steps {
   $value: $i * $spacing-increment;

   .m-#{$i} {
      margin: $value !important;
   }

   .p-#{$i} {
      padding: $value !important;
   }

   .ml-#{$i} {
      margin-left: $value !important;
   }

   .mt-#{$i} {
      margin-top: $value !important;
   }

   .mr-#{$i} {
      margin-right: $value !important;
   }

   .mb-#{$i} {
      margin-bottom: $value !important;
   }

   .mx-#{$i} {
      margin-left: $value !important;
      margin-right: $value !important;
   }

   .my-#{$i} {
      margin-top: $value !important;
      margin-bottom: $value !important;
   }

   .pl-#{$i} {
      padding-left: $value !important;
   }

   .pt-#{$i} {
      padding-top: $value !important;
   }

   .pr-#{$i} {
      padding-right: $value !important;
   }

   .pb-#{$i} {
      padding-bottom: $value !important;
   }

   .px-#{$i} {
      padding-left: $value !important;
      padding-right: $value !important;
   }

   .py-#{$i} {
      padding-top: $value !important;
      padding-bottom: $value !important;
   }

   // Extended
   .gap-#{$i} {
      gap: $value !important;
   }
}
