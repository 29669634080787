/** Styles similar to vuetify */

$textAlignments: ('left', 'right', 'center', 'revert');

@each $textAlign in $textAlignments {
   .text-align-#{$textAlign} {
      text-align: #{$textAlign} !important;
   }
}

$displayProps: (
   'block',
   'inline-block',
   'inline',
   'flex',
   'grid',
   'inline-flex',
   'inline-grid',
   'table',
   'inline-table',
   'list-item',
   'none',
   'contents'
);

@each $disp in $displayProps {
   .d-#{$disp} {
      display: #{$disp} !important;
   }
}

.flex-column {
   flex-direction: column !important;
}
.flex-row {
   flex-direction: row !important;
}


$alignments: ('start', 'end', 'center', 'baseline', 'stretch');

@each $align in $alignments {
   .align-#{$align} {
      align-items: #{$align} !important;
   }
}



$justifyopts: ('start', 'end', 'center', 'space-between', 'space-around');

@each $justify in $justifyopts {
   .justify-#{$justify} {
      justify-content: #{$justify} !important;
   }
}


$selfalignments: ('start', 'end', 'center', 'baseline', 'auto', 'stretch');

@each $selfalign in $selfalignments {
   .align-self-#{$selfalign} {
      align-self: #{$selfalign} !important;
   }
}


$aligncontentopts: ('start', 'end', 'center', 'space-between', 'space-around', 'stretch');

@each $aligncontent in $aligncontentopts {
   .align-content-#{$aligncontent} {
      align-content: #{$aligncontent} !important;
   }
}

.flex-grow-0 {
   flex-grow: 0 !important;
}

.flex-grow-1 {
   flex-grow: 1 !important;
}

.flex-shrink-0 {
   flex-shrink: 0 !important;
}

.flex-shrink-1 {
   flex-shrink: 1 !important;
}


