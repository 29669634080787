/* You can add global styles to this file, and also import other style files */
// -- Angular Material -- //
@use '@angular/material' as mat;

@import 'design_colors';
@import 'spacing';
@import 'class_styles';

// material defaults: see: @angular/material/theming/_palette.scss
// From: https://atlassian.design/components/tokens/all-tokens#color-text
$dark-primary-text: $Neutral1000; // rgba(black, 0.87);
//$dark-secondary-text: $Neutral800; // rgba(black, 0.54);
//$dark-disabled-text: $Neutral400A; // rgba(black, 0.38);
//$dark-dividers: rgba(black, 0.12);
//$dark-focused: rgba(black, 0.12);

$light-primary-text: $DarkNeutral100; //white;
//$light-secondary-text: $DarkNeutral800; // rgba(white, 0.7);
//$light-disabled-text: $DarkNeutral400A; // rgba(white, 0.5);
//$light-dividers: rgba(white, 0.12);
//$light-focused: rgba(white, 0.12);

// Trello palette from: https://blog.trello.com/the-how-and-why-behind-trellos-visual-brand-guide
// - see: https://github.com/trello/trellicolors/blob/master/build/brand-colors.scss
$trello-palette-light: (
   50: $Blue50-extra,
   100: $Blue100,
   200: $Blue200,
   300: $Blue300,
   400: $Blue400,
   500: $Blue500,
   600: $Blue600,
   700: $Blue700,
   800: $Blue800,
   900: $Blue900,
   // A's copied from material blue
   /*
   A100: #82b1ff,
   A200: #448aff,
   A400: #2979ff,
   A700: #2962ff,
   */ contrast:
      (
         50: $dark-primary-text,
         100: $dark-primary-text,
         200: $dark-primary-text,
         300: $dark-primary-text,
         400: $dark-primary-text,
         500: $light-primary-text,
         600: $light-primary-text,
         700: $light-primary-text,
         800: $light-primary-text,
         900: $light-primary-text,
         A100: $dark-primary-text,
         A200: $light-primary-text,
         A400: $light-primary-text,
         A700: $light-primary-text,
      ),
);

$trello-purple-palette-light: (
   50: $Purple50-extra,
   100: $Purple100,
   200: $Purple200,
   300: $Purple300,
   400: $Purple400,
   500: $Purple500,
   600: $Purple600,
   700: $Purple700,
   800: $Purple800,
   900: $Purple900,
   // A's copied from material purple
   //A100: #ea80fc,
   //A200: #e040fb,
   //A400: #d500f9,
   //A700: #aa00ff,
   contrast:
      (
         50: $dark-primary-text,
         100: $dark-primary-text,
         200: $dark-primary-text,
         300: $light-primary-text,
         400: $light-primary-text,
         500: $light-primary-text,
         600: $light-primary-text,
         700: $light-primary-text,
         800: $light-primary-text,
         900: $light-primary-text,
         A100: $dark-primary-text,
         A200: $light-primary-text,
         A400: $light-primary-text,
         A700: $light-primary-text,
      ),
);

$trello-palette-dark: (
   50: $Blue900,
   // extra
   100: $Blue900,
   200: $Blue800,
   300: $Blue700,
   400: $Blue600,
   500: $Blue500,
   600: $Blue400,
   700: $Blue300,
   800: $Blue200,
   900: $Blue100,
   contrast: (
      50: $light-primary-text,
      100: $light-primary-text,
      200: $light-primary-text,
      300: $light-primary-text,
      400: $light-primary-text,

      500: $dark-primary-text,
      600: $dark-primary-text,
      700: $dark-primary-text,
      800: $dark-primary-text,
      900: $dark-primary-text,

      A100: $light-primary-text,

      A200: $dark-primary-text,
      A400: $dark-primary-text,
      A700: $dark-primary-text,
   ),
);

$trello-purple-palette-dark: (
   50: $Purple900,
   // extra
   100: $Purple900,
   200: $Purple800,
   300: $Purple700,
   400: $Purple600,
   500: $Purple500,
   600: $Purple400,
   700: $Purple300,
   800: $Purple200,
   900: $Purple100,
   contrast: (
      50: $light-primary-text,
      100: $light-primary-text,
      200: $light-primary-text,
      300: $light-primary-text,
      400: $light-primary-text,

      500: $dark-primary-text,
      600: $dark-primary-text,
      700: $dark-primary-text,
      800: $dark-primary-text,
      900: $dark-primary-text,

      A100: $light-primary-text,

      A200: $dark-primary-text,
      A400: $dark-primary-text,
      A700: $dark-primary-text,
   ),
);

@include mat.core();

$tr-primary-light: mat.define-palette($trello-palette-light, 500);
$tr-accent-light: mat.define-palette($trello-purple-palette-light); //, A200, A100, A400);

$tr-primary-dark: mat.define-palette($trello-palette-dark, 500);
$tr-accent-dark: mat.define-palette($trello-purple-palette-dark); //, A200, A100, A400);

$tr-theme-light: mat.define-light-theme(
   (
      color: (
         primary: $tr-primary-light,
         accent: $tr-accent-light,
      ),
   )
);
$tr-theme-dark: mat.define-dark-theme(
   (
      color: (
         primary: $tr-primary-dark,
         accent: $tr-accent-dark,
      ),
   )
);

// Emit theme-dependent styles for common features used across multiple components.
// Include just what we need
// - see: https://github.com/angular/components/blob/master/src/material/core/theming/_all-theme.scss#L40
@include mat.core-theme($tr-theme-light);
@include mat.button-theme($tr-theme-light);
@include mat.button-toggle-theme($tr-theme-light);
@include mat.checkbox-theme($tr-theme-light);
@include mat.slide-toggle-theme($tr-theme-light);
@include mat.tooltip-theme($tr-theme-light);
@include mat.select-theme($tr-theme-light);
@include mat.menu-theme($tr-theme-light);
@include mat.progress-bar-theme($tr-theme-light);
@include mat.progress-spinner-theme($tr-theme-light);
@include mat.expansion-theme($tr-theme-light);
@include mat.tabs-theme($tr-theme-light);
@include mat.table-theme($tr-theme-light);

// Style for dark theme overrides

html[data-color-mode='dark'] {
   @include mat.core-color($tr-theme-dark);
   @include mat.button-color($tr-theme-dark);
   @include mat.button-toggle-color($tr-theme-dark);
   @include mat.checkbox-color($tr-theme-dark);
   @include mat.slide-toggle-color($tr-theme-dark);
   @include mat.tooltip-color($tr-theme-dark);
   @include mat.select-color($tr-theme-dark);
   @include mat.menu-color($tr-theme-dark);
   @include mat.progress-bar-color($tr-theme-dark);
   @include mat.progress-spinner-color($tr-theme-dark);
   @include mat.expansion-color($tr-theme-dark);
   @include mat.tabs-color($tr-theme-dark);
   @include mat.table-color($tr-theme-dark);
}

// Add Missing Trello Colors
html[data-color-mode='light'] {
   --tr-background-accent-lime-subtlest: #eefbda;
   --tr-background-accent-lime-subtler: #d3f1a7;
   --tr-background-accent-lime-subtle: #94c748;
   --tr-background-accent-lime-bolder: #5b7f24;
   --tr-border-accent-lime: #6a9a23;
   --tr-icon-accent-lime: #6a9a23;
   --tr-icon-star: #e2b203;
   --tr-text-accent-lime: #4c6b1f;
   --tr-text-accent-lime-bolder: #37471f;
}

html[data-color-mode='dark'] {
   --tr-background-accent-lime-subtlest: #273315;
   --tr-background-accent-lime-subtler: #37471f;
   --tr-background-accent-lime-subtle: #4c6b1f;
   --tr-background-accent-lime-bolder: #94c748;
   --tr-border-accent-lime: #82b536;
   --tr-icon-accent-lime: #82b536;
   --tr-icon-star: #e2b203;
   --tr-text-accent-lime: #b3df72;
   --tr-text-accent-lime-bolder: #d3f1a7;
}

// ---- THIRD PARTY COMPONENTS --- //
// emoji-mart
@import '~@ctrl/ngx-emoji-mart/picker';

// ng-select
@import '~@ng-select/ng-select/scss/default.theme';

// See Trello base style:
// - https://p.trellocdn.com/power-up.css
@import 'settings';

/**  Angular Material
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
*/

html,
body {
   height: 100%;

   &.hide-body-overflow {
      // have contained items handle the overflow
      overflow: hidden;
   }
}
body {
   margin: 0;
}

// ------ Angular CDK Drag and Drop List defaults ---- //
// Common CDK drag and drop styling

/** Hide the preview */
.cdk-drag-placeholder {
   opacity: 0;
}

.cdk-drag-animating {
   transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// The "preview" DOM element on the cursor
.cdk-drag-preview {
   box-sizing: border-box;
   border-radius: 4px;
   box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

// Include the theme mixins for other components you use here.

// Common scrollbar overrides
// - pull from powerups defaults
:-webkit-scrollbar {
   height: 8px;
   width: 8px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
   background: transparent;
   display: none;
}
::-webkit-scrollbar-track-piece {
   background: $ds-border;
}
::-webkit-scrollbar-track-piece:vertical:start {
   border-radius: 4px 4px 0 0;
}
::-webkit-scrollbar-track-piece:vertical:end {
   border-radius: 0 0 4px 4px;
}
::-webkit-scrollbar-track-piece:horizontal:start {
   border-radius: 4px 0 0 4px;
}
::-webkit-scrollbar-track-piece:horizontal:end {
   border-radius: 0 4px 4px 0;
}
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
   background: $ds-border;
   border-radius: 4px;
   display: block;
   height: 48px;
}

// Make mat tooltips look reasonable
.mat-tooltip-panel {
   .mat-tooltip {
      font-size: $font-std;
   }
}

// Custom mat dialog class to keep overflow unset.
.cdk-overlay-pane.tr-mat-dialog-no-overflow {
   mat-dialog-container {
      overflow: hidden;
   }
}

// --- Custom Component styles ----------- //
[trCenter] {
   display: flex;
   align-items: center;
   justify-content: center;
   align-content: center;
}

[trDashUnderline] {
   text-decoration-line: underline;
   text-decoration-style: dotted;
}

// todo: clean up more inline with atl design system
[trMessage] {
   // From .ui.message
   font-size: 1em;

   position: relative;
   min-height: 1em;
   margin: 1em 0;
   background: $ds-background-neutral;
   padding: 0.75em 1em;
   line-height: 1.4285em;
   color: $ds-text; // #17394d;
   //-webkit-transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease;
   transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
   border-radius: 3px; // 0.28571429rem;
   //box-shadow: 0 0 0 1px rgb(34 36 38 / 22%) inset, 0 0 0 0 transparent;

   p {
      //opacity: 0.85;
      margin: 0.75em 0;
      //line-height: 1.4285em;
   }

   /**
   * Case where we have an fa-icon immediately inside the message
   * <div trMessage trMessageIcon><fa-icon></fa-icon><div>...</div></div>
   */
   &[trMessageIcon] {
      display: flex;
      flex-direction: row;
      align-items: center;
      fa-icon {
         margin-right: $margin-med;
      }
   }

   &[trMessageInfo] {
      background: $ds-background-information;
      border-color: $ds-background-information;
   }

   &[trMessageWarn] {
      background: $ds-background-warning;
      border-color: $ds-background-warning;
   }

   &[trMessageError] {
      background: $ds-background-danger;
      border-color: $ds-background-danger;
   }
}

/**
* Reusable chip styling based upon bootstrap 4 "badge"
*/
[trChip] {
   display: inline-block;
   padding: 0.25em 0.4em;
   margin: 0 0.5em;
   font-size: 0.75rem;
   font-weight: 700;
   line-height: 1;
   text-align: center;
   white-space: nowrap;
   vertical-align: baseline;
   border-radius: 0.25rem;

   color: $ds-text-inverse;
   background-color: $ds-text-subtlest;

   // Add variants here when needed
   &.mod-primary {
      background-color: $ds-background-brand-bold;
   }
   &.mod-new {
      background-color: $ds-background-discovery-bold;
   }
   &.mod-danger {
      background-color: $ds-background-danger-bold;
   }
}

/**
 * Spacer that takes up space from the style.
 */
[trSpacer] {
   flex: 1;
}

/** style an unordered list as normal. */
ul[trListNormal] {
   list-style: disc;
   margin: 1em 0;
   padding-left: 40px;
}

/**
* Attribute to use to get an fa-icon with styling for clicking
* like an action button.
*/
[trActionIcon] {
   font-size: 18px;
   color: $ds-border;

   &:hover {
      color: $ds-icon-selected;
      cursor: pointer;
   }

   &.mod-danger:hover {
      color: $ds-icon-danger;
   }
}

/**
* Provide padding for an inline icon.
*/
[trIconPadded] {
   padding: 0 $padding-med;
}

[trIconButton] {
   fa-icon {
      // Add padding between icon and word.
      // button has the rest of the padding
      padding: 0;
      padding-right: $padding-small;
   }
}

// Icon button that shoudl be smaller in height/width
[trIconButtonSmall] {
   padding: 3px $padding-med;
   padding-left: 0;
   margin: 0;

   fa-icon {
      padding: 0 $padding-small;
   }
}

// Other mod variations for buttons
// - mod-primary
// - mod-danger
button {
   &.mod-accent {
      background-color: $ds-background-discovery-bold;
      box-shadow: none;
      border: none;
      color: $ds-text-inverse;

      &:hover {
         background-color: $ds-background-discovery-bold-hovered;
      }
   }

   &.mod-neutral {
      background-color: $ds-background-neutral-bold;
      box-shadow: none;
      border: none;
      color: $ds-text-inverse;

      &:hover {
         background-color: $ds-background-neutral-bold-hovered;
      }
   }

   &.mod-success {
      background-color: $ds-background-success-bold;
      box-shadow: none;
      border: none;
      color: $ds-text-inverse;

      &:hover {
         background-color: $ds-background-success-bold-hovered;
      }
   }
}

.tr-card {
   position: relative;
   display: flex;
   flex-direction: column;
   background: $card-bg;
   padding: 0;
   border: none;
   border-radius: $card-border-radius;
   // FIXSTYLE
   box-shadow: 0px 1px 3px 0px #d4d4d5, 0px 0px 0px 1px #d4d4d5;
}

/** Override the default settings for powerups */
// FIX STYLE
.tr-form-fieldset {
   border: 1px solid $ds-border;
   border-radius: 3px;
   padding: $padding-small $padding-med;
   margin: 0;
   margin-top: $margin-med;
   @include mat.elevation(1);

   legend {
      padding: 0 $padding-small;
      margin: 0 $margin-small;
      //font-size: 1.1rem;
   }

   /** Fieldset nested inside another */
   &.nested {
      border: none;
      padding: $padding-small 0;
      box-shadow: none;

      legend {
         padding: 0;
         margin: 0;
         text-decoration: underline;
      }
   }

   &.disabled-supporter {
      // Show the legend as "disabled"
      legend {
         color: $ds-text-disabled;
      }

      // Disable everything in the fieldset that is not the legend
      :not(legend, legend *) {
         cursor: not-allowed !important;
      }
   }
}

[trFormField],
.tr-form-field {
   display: flex;
   flex-direction: row;
   align-items: baseline;

   &[trFormFieldColumn] {
      display: flex;
      flex-direction: column;
   }

   label {
      margin-right: $margin-med;
      margin-top: $margin-med;
      text-align: right;

      // Helps to center when we add things like help icons or badges to the labels
      display: flex;
      flex-direction: row;
      align-items: center;
   }

   input[type='checkbox'] {
      margin-left: $margin-med;
      margin-right: $margin-med;
      box-shadow: inset 0 0 0 2px $ds-border-input;

      &:checked {
         // Go back tot he default input box-shadow
         box-shadow: none;
      }

      &:disabled {
         cursor: not-allowed;
      }
   }

   /** Override text to make look consistent with select and form field. */
   input[type='text'] {
      width: 100%;
      max-width: 274px;

      &[trAllowWideInput] {
         max-width: revert;
      }
   }

   // powerup.css doesn't style number input the same as text-based fields
   // this sequence makes then look similar
   // FIXSTYLE
   input[type='number']:hover {
      background-color: $ds-background-input-hovered;
      border: none;
      box-shadow: inset 0 0 0 2px $ds-border-input;
   }
   input[type='number']:hover.is-error {
      background-color: $ds-background-danger;
      border: none;
      box-shadow: inset 0 0 0 2px $ds-border-danger;
   }
   input[type='number']:focus {
      background: $ds-background-input-pressed;
      border: none;
      box-shadow: inset 0 0 0 2px $ds-border-focused;
   }
   input[type='number']:focus.is-error {
      background-color: $ds-background-input-pressed;
      border: none;
      box-shadow: inset 0 0 0 2px $ds-border-danger;
   }
   input[type='number']:disabled {
      background-color: $ds-background-disabled;
      box-shadow: none;
      color: $ds-text-disabled;
      cursor: not-allowed;
      font-weight: normal;
      user-select: none;
   }

   // Style similar to text input
   mat-select {
      flex: 1;
      background-color: $ds-background-input;
      border: none;
      box-shadow: inset 0 0 0 2px $ds-border-input;
      color: $ds-text;
      border-radius: 3px;
      line-height: 20px;
      margin-bottom: 12px;
      padding: 8px 12px;

      // border: 1px grey solid;
      // border-radius: 3px;
      width: 100%;
      max-width: 250px;

      &[trAllowWideSelect] {
         max-width: revert;
      }

      // mat-select auto sizing
      // see: https://github.com/angular/components/issues/7627#issuecomment-505646063
      &[trAutoSizeSelect] {
         width: fit-content !important;

         // this line caused spacing issues on right
         // min-width: 100%;

         .mat-form-field-infix {
            min-width: 75px !important;
            width: fit-content !important;
         }

         .mat-select-value {
            width: fit-content;
            min-width: 2ch;
            max-width: 25ch;
         }
         .mat-select-placeholder {
            width: fit-content;
            min-width: 10ch;
         }
      }
   }

   mat-button-toggle-group {
      // FIXSTYLE: improve toggle group
      background-color: $ds-background-input;
      border: none;
      //box-shadow: inset 0 0 0 2px #dfe1e6;
      color: $ds-text;
      border-radius: 3px;
      //line-height: 20px;
      margin-bottom: 12px;
      //padding: 8px 12px;
      border: 2px solid $ds-border; // #dfe1e6;

      .mat-button-toggle-checked {
         background-color: $ds-background-selected;
      }
      button {
         span.mat-button-toggle-label-content {
            line-height: 32px;
         }
      }
   }
}

[trClickable] {
   cursor: pointer;
}

// Make local clickable links look like links
[trClickableLink] {
   color: $ds-link;
   text-decoration: underline;
   cursor: pointer;
   //font-size: $font-larger;
}

[trSettingsSection] {
   margin: $margin-large $margin-large;
   margin-bottom: $margin-large;
   display: flex;
   flex-direction: column;

   [trSettingsHeader] {
      font-size: $font-large;
      margin: $margin-small 0 (1.5 * $margin-large) 0;
      font-weight: bold;

      fa-icon {
         margin: 0 $margin-med;
      }
   }

   [trSettingsBody] {
      margin: 0 ($margin-large * 1.5);
      margin-bottom: $margin-med;

      .description,
      .instructions {
         // todo: add font styling or something
      }
   }

   [trMessage] {
      margin: 0 ($margin-large * 2);
   }

   // Line of centered buttons
   .button-bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
   }

   // Get the settings areas looking ok.
   .settings-fields {
      //margin-left: $margin-med;

      .field-group-name {
         margin-left: $margin-small;
      }
   }
}

[trSettingsDivider].mat-divider {
   border-color: $ds-border;
   margin: 0 $margin-large * 2;
}

// Board that is attention grabbing
[trAttentionBorder] {
   --borderWidth: 3px;
   background: $ds-background-neutral;
   position: relative;
   border-radius: var(--borderWidth);
}
[trAttentionBorder]:after {
   content: '';
   position: absolute;
   top: calc(-1 * var(--borderWidth));
   left: calc(-1 * var(--borderWidth));
   height: calc(100% + var(--borderWidth) * 2);
   width: calc(100% + var(--borderWidth) * 2);
   background: linear-gradient(
      60deg,
      #f79533,
      #f37055,
      #ef4e7b,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82
   );
   border-radius: calc(2 * var(--borderWidth));
   z-index: -1;
   animation: attentionBorderAnimatedGradient 2.5s ease alternate infinite;
   background-size: 300% 300%;
}

@keyframes attentionBorderAnimatedGradient {
   0% {
      background-position: 0% 50%;
   }
   50% {
      background-position: 100% 50%;
   }
   100% {
      background-position: 0% 50%;
   }
}

// Border Pulse: see: https://atlassian.design/components/onboarding/examples#pulse
[trPulseBorder] {
   border-radius: 3px;
   z-index: 701;

   animation: pulseBorder 3000ms cubic-bezier(0.55, 0.055, 0.675, 0.19) infinite;
}

@keyframes pulseBorder {
   0%,
   33% {
      box-shadow: 0 0 0 2px $ds-border-discovery, 0 0 0 $ds-border-discovery;
   }
   66%,
   100% {
      box-shadow: 0 0 0 2px $ds-border-discovery, 0 0 0 10px rgba(101, 84, 192, 0.01);
   }
}

// ----- Trello Styling Overrides --- //

// ----- Mouse Flow Styling Overrides --- //
#mouseflow .mf-feedback {
   .mf-widget-powered {
      display: none;
   }
}

// --- Beacon Overrides --- //
#beacon-container .hsds-beacon {
   .BeaconFabButtonFrame {
      bottom: 10px;
      right: 20px;
   }
   .BeaconContainer {
      right: 40px - 20px;
      bottom: 115px - 40px;
   }
}

// --- Churn Key Style overrides --- //
$base-ck-font-size: 16px;

// see: https://docs.churnkey.co/installing-churnkey#cbd4bc78f65d448daa133c0dd09c66a3
#ck-app.ck-style {
   .sm\:max-w-lg {
      max-width: 32 * $base-ck-font-size !important;
   }

   // Override all text sizes because our rem is smaller
   .text-xs {
      font-size: 0.75 * $base-ck-font-size !important;
      line-height: 1 * $base-ck-font-size !important;
   }

   .sm\:text-sm,
   .text-sm {
      font-size: 0.875 * $base-ck-font-size !important;
      line-height: 1.25 * $base-ck-font-size !important;
   }

   .text-base {
      font-size: 1 * $base-ck-font-size !important;
      line-height: 1.5 * $base-ck-font-size !important;
   }

   .text-lg {
      font-size: 1.125 * $base-ck-font-size !important;
      line-height: 1.75 * $base-ck-font-size !important;
   }

   .text-xl {
      font-size: 1.25 * $base-ck-font-size !important;
      line-height: 1.75 * $base-ck-font-size !important;
   }

   .text-2xl {
      font-size: 1.5 * $base-ck-font-size !important;
      line-height: 2 * $base-ck-font-size !important;
   }

   .text-3xl {
      font-size: 1.875 * $base-ck-font-size !important;
      line-height: 2.25 * $base-ck-font-size !important;
   }

   [type='email'],
   [type='month'],
   [type='number'],
   [type='search'],
   [type='text'],
   [type='time'],
   select,
   textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #fff;
      border-color: #6b7280;
      border-width: 1px;
      border-radius: 0;
      padding-top: 0.5 * $base-ck-font-size;
      padding-right: 0.75 * $base-ck-font-size;
      padding-bottom: 0.5 * $base-ck-font-size;
      padding-left: 0.75 * $base-ck-font-size;
      font-size: 1 * $base-ck-font-size;
      line-height: 1.5 * $base-ck-font-size;
   }

   .ck-survey-step {
      // undo powerup.css settings that interfere
      input[type='radio'] {
         min-height: auto;

         border-color: #6b7280;
         border-width: 1px;
         border-radius: 100%;

         box-shadow: none;
         box-sizing: border-box;

         //.ck-style [type=radio] {
         -webkit-appearance: none;
         -moz-appearance: none;
         appearance: none;
         padding: 0;
         -webkit-print-color-adjust: exact;
         color-adjust: exact;
         display: inline-block;
         vertical-align: middle;
         background-origin: border-box;
         -webkit-user-select: none;
         -moz-user-select: none;
         -ms-user-select: none;
         user-select: none;
         -ms-flex-negative: 0;
         flex-shrink: 0;
         height: 1rem;
         width: 1rem;
         color: #2563eb;
         background-color: #fff;
         border: 1px solid;
         border-color: #6b7280;
         border-width: 1px;
         border-radius: 100%;
         //}

         &:checked,
         &:checked:focus,
         &:checked:hover {
            border-color: transparent;
            background-color: currentColor;
         }
      }

      ul {
         // Override the text sizing
         .text-sm {
            font-size: 1 * $base-ck-font-size !important;
         }

         li.flex {
            align-items: center;
            font-size: 1rem !important;
         }

         label {
            color: #5e6c84;
            display: flex;
            font-size: inherit;
            font-weight: inherit; // 400;
            line-height: inherit; // 18.4px;
            margin-bottom: 0;
            margin-top: 0;
         }
      }
   }
}
