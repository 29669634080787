// Redirects to use for design tokens in trasition of Trello theming
//
// see::
//  - color roles / meanings: https://atlassian.design/foundations/color-new
//
@import 'design_colors';

$ds-text: var(--ds-text, #172b4d);
$ds-text-accent-red: var(--ds-text-accent-red, #ae2a19);
$ds-text-accent-red-bolder: var(--ds-text-accent-red-bolder, #601e16);
$ds-text-accent-orange: var(--ds-text-accent-orange, #974f0c);
$ds-text-accent-orange-bolder: var(--ds-text-accent-orange-bolder, #5f3811);
$ds-text-accent-yellow: var(--ds-text-accent-yellow, #7f5f01);
$ds-text-accent-yellow-bolder: var(--ds-text-accent-yellow-bolder, #533f04);
$ds-text-accent-green: var(--ds-text-accent-green, #216e4e);
$ds-text-accent-green-bolder: var(--ds-text-accent-green-bolder, #164b35);
$ds-text-accent-teal: var(--ds-text-accent-teal, #206b74);
$ds-text-accent-teal-bolder: var(--ds-text-accent-teal-bolder, #1d474c);
$ds-text-accent-blue: var(--ds-text-accent-blue, #0055cc);
$ds-text-accent-blue-bolder: var(--ds-text-accent-blue-bolder, #09326c);
$ds-text-accent-purple: var(--ds-text-accent-purple, #5e4db2);
$ds-text-accent-purple-bolder: var(--ds-text-accent-purple-bolder, #352c63);
$ds-text-accent-magenta: var(--ds-text-accent-magenta, #943d73);
$ds-text-accent-magenta-bolder: var(--ds-text-accent-magenta-bolder, #50253f);
$ds-text-accent-gray: var(--ds-text-accent-gray, #44546f);
$ds-text-accent-gray-bolder: var(--ds-text-accent-gray-bolder, #091e42);
$ds-text-disabled: var(--ds-text-disabled, #091e424f);
$ds-text-inverse: var(--ds-text-inverse, #ffffff);
$ds-text-selected: var(--ds-text-selected, #0c66e4);
$ds-text-brand: var(--ds-text-brand, #0c66e4);
$ds-text-danger: var(--ds-text-danger, #ae2a19);
$ds-text-warning: var(--ds-text-warning, #974f0c);
$ds-text-warning-inverse: var(--ds-text-warning-inverse, #172b4d);
$ds-text-success: var(--ds-text-success, #216e4e);
$ds-text-discovery: var(--ds-text-discovery, #5e4db2);
$ds-text-information: var(--ds-text-information, #0055cc);
$ds-text-subtlest: var(--ds-text-subtlest, #626f86);
$ds-text-subtle: var(--ds-text-subtle, #44546f);
$ds-link: var(--ds-link, #0c66e4);
$ds-link-pressed: var(--ds-link-pressed, #0055cc);
$ds-icon: var(--ds-icon, #44546f);
$ds-icon-accent-red: var(--ds-icon-accent-red, #e34935);
$ds-icon-accent-orange: var(--ds-icon-accent-orange, #d97008);
$ds-icon-accent-yellow: var(--ds-icon-accent-yellow, #b38600);
$ds-icon-accent-green: var(--ds-icon-accent-green, #22a06b);
$ds-icon-accent-teal: var(--ds-icon-accent-teal, #1d9aaa);
$ds-icon-accent-blue: var(--ds-icon-accent-blue, #1d7afc);
$ds-icon-accent-purple: var(--ds-icon-accent-purple, #8270db);
$ds-icon-accent-magenta: var(--ds-icon-accent-magenta, #cd519d);
$ds-icon-accent-gray: var(--ds-icon-accent-gray, #758195);
$ds-icon-disabled: var(--ds-icon-disabled, #091e424f);
$ds-icon-inverse: var(--ds-icon-inverse, #ffffff);
$ds-icon-selected: var(--ds-icon-selected, #0c66e4);
$ds-icon-brand: var(--ds-icon-brand, #0c66e4);
$ds-icon-danger: var(--ds-icon-danger, #e34935);
$ds-icon-warning: var(--ds-icon-warning, #d97008);
$ds-icon-warning-inverse: var(--ds-icon-warning-inverse, #172b4d);
$ds-icon-success: var(--ds-icon-success, #22a06b);
$ds-icon-discovery: var(--ds-icon-discovery, #8270db);
$ds-icon-information: var(--ds-icon-information, #1d7afc);
$ds-icon-subtle: var(--ds-icon-subtle, #626f86);
$ds-border: var(--ds-border, #091e4224);
$ds-border-accent-red: var(--ds-border-accent-red, #e34935);
$ds-border-accent-orange: var(--ds-border-accent-orange, #d97008);
$ds-border-accent-yellow: var(--ds-border-accent-yellow, #b38600);
$ds-border-accent-green: var(--ds-border-accent-green, #22a06b);
$ds-border-accent-teal: var(--ds-border-accent-teal, #1d9aaa);
$ds-border-accent-blue: var(--ds-border-accent-blue, #1d7afc);
$ds-border-accent-purple: var(--ds-border-accent-purple, #8270db);
$ds-border-accent-magenta: var(--ds-border-accent-magenta, #cd519d);
$ds-border-accent-gray: var(--ds-border-accent-gray, #758195);
$ds-border-disabled: var(--ds-border-disabled, #091e420f);
$ds-border-focused: var(--ds-border-focused, #388bff);
$ds-border-input: var(--ds-border-input, #091e4224);
$ds-border-inverse: var(--ds-border-inverse, #ffffff);
$ds-border-selected: var(--ds-border-selected, #0c66e4);
$ds-border-brand: var(--ds-border-brand, #0c66e4);
$ds-border-danger: var(--ds-border-danger, #e34935);
$ds-border-warning: var(--ds-border-warning, #d97008);
$ds-border-success: var(--ds-border-success, #22a06b);
$ds-border-discovery: var(--ds-border-discovery, #8270db);
$ds-border-information: var(--ds-border-information, #1d7afc);
$ds-border-bold: var(--ds-border-bold, #758195);
$ds-background-accent-red-subtlest: var(--ds-background-accent-red-subtlest, #ffedeb);
$ds-background-accent-red-subtler: var(--ds-background-accent-red-subtler, #ffd2cc);
$ds-background-accent-red-subtle: var(--ds-background-accent-red-subtle, #f87462);
$ds-background-accent-red-bolder: var(--ds-background-accent-red-bolder, #ca3521);
$ds-background-accent-orange-subtlest: var(--ds-background-accent-orange-subtlest, #fff4e5);
$ds-background-accent-orange-subtler: var(--ds-background-accent-orange-subtler, #ffe2bd);
$ds-background-accent-orange-subtle: var(--ds-background-accent-orange-subtle, #faa53d);
$ds-background-accent-orange-bolder: var(--ds-background-accent-orange-bolder, #b65c02);
$ds-background-accent-yellow-subtlest: var(--ds-background-accent-yellow-subtlest, #fff7d6);
$ds-background-accent-yellow-subtler: var(--ds-background-accent-yellow-subtler, #f8e6a0);
$ds-background-accent-yellow-subtle: var(--ds-background-accent-yellow-subtle, #e2b203);
$ds-background-accent-yellow-bolder: var(--ds-background-accent-yellow-bolder, #946f00);
$ds-background-accent-green-subtlest: var(--ds-background-accent-green-subtlest, #dffcf0);
$ds-background-accent-green-subtler: var(--ds-background-accent-green-subtler, #baf3db);
$ds-background-accent-green-subtle: var(--ds-background-accent-green-subtle, #4bce97);
$ds-background-accent-green-bolder: var(--ds-background-accent-green-bolder, #1f845a);
$ds-background-accent-teal-subtlest: var(--ds-background-accent-teal-subtlest, #e3fafc);
$ds-background-accent-teal-subtler: var(--ds-background-accent-teal-subtler, #c1f0f5);
$ds-background-accent-teal-subtle: var(--ds-background-accent-teal-subtle, #60c6d2);
$ds-background-accent-teal-bolder: var(--ds-background-accent-teal-bolder, #1d7f8c);
$ds-background-accent-blue-subtlest: var(--ds-background-accent-blue-subtlest, #e9f2ff);
$ds-background-accent-blue-subtler: var(--ds-background-accent-blue-subtler, #cce0ff);
$ds-background-accent-blue-subtle: var(--ds-background-accent-blue-subtle, #579dff);
$ds-background-accent-blue-bolder: var(--ds-background-accent-blue-bolder, #0c66e4);
$ds-background-accent-purple-subtlest: var(--ds-background-accent-purple-subtlest, #f3f0ff);
$ds-background-accent-purple-subtler: var(--ds-background-accent-purple-subtler, #dfd8fd);
$ds-background-accent-purple-subtle: var(--ds-background-accent-purple-subtle, #9f8fef);
$ds-background-accent-purple-bolder: var(--ds-background-accent-purple-bolder, #6e5dc6);
$ds-background-accent-magenta-subtlest: var(--ds-background-accent-magenta-subtlest, #ffecf8);
$ds-background-accent-magenta-subtler: var(--ds-background-accent-magenta-subtler, #fdd0ec);
$ds-background-accent-magenta-subtle: var(--ds-background-accent-magenta-subtle, #e774bb);
$ds-background-accent-magenta-bolder: var(--ds-background-accent-magenta-bolder, #ae4787);
$ds-background-accent-gray-subtlest: var(--ds-background-accent-gray-subtlest, #f1f2f4);
$ds-background-accent-gray-subtler: var(--ds-background-accent-gray-subtler, #dcdfe4);
$ds-background-accent-gray-subtle: var(--ds-background-accent-gray-subtle, #8590a2);
$ds-background-accent-gray-bolder: var(--ds-background-accent-gray-bolder, #626f86);
$ds-background-disabled: var(--ds-background-disabled, #091e4208);
$ds-background-input: var(--ds-background-input, #ffffff);
$ds-background-input-hovered: var(--ds-background-input-hovered, #f7f8f9);
$ds-background-input-pressed: var(--ds-background-input-pressed, #ffffff);
$ds-background-inverse-subtle: var(--ds-background-inverse-subtle, #00000029);
$ds-background-inverse-subtle-hovered: var(--ds-background-inverse-subtle-hovered, #0000003d);
$ds-background-inverse-subtle-pressed: var(--ds-background-inverse-subtle-pressed, #00000052);
$ds-background-neutral: var(--ds-background-neutral, #091e420f);
$ds-background-neutral-hovered: var(--ds-background-neutral-hovered, #091e4224);
$ds-background-neutral-pressed: var(--ds-background-neutral-pressed, #091e424f);
$ds-background-neutral-subtle: var(--ds-background-neutral-subtle, #00000000);
$ds-background-neutral-subtle-hovered: var(--ds-background-neutral-subtle-hovered, #091e420f);
$ds-background-neutral-subtle-pressed: var(--ds-background-neutral-subtle-pressed, #091e4224);
$ds-background-neutral-bold: var(--ds-background-neutral-bold, #44546f);
$ds-background-neutral-bold-hovered: var(--ds-background-neutral-bold-hovered, #2c3e5d);
$ds-background-neutral-bold-pressed: var(--ds-background-neutral-bold-pressed, #172b4d);
$ds-background-selected: var(--ds-background-selected, #e9f2ff);
$ds-background-selected-hovered: var(--ds-background-selected-hovered, #cce0ff);
$ds-background-selected-pressed: var(--ds-background-selected-pressed, #85b8ff);
$ds-background-selected-bold: var(--ds-background-selected-bold, #0c66e4);
$ds-background-selected-bold-hovered: var(--ds-background-selected-bold-hovered, #0055cc);
$ds-background-selected-bold-pressed: var(--ds-background-selected-bold-pressed, #09326c);
$ds-background-brand-bold: var(--ds-background-brand-bold, #0c66e4);
$ds-background-brand-bold-hovered: var(--ds-background-brand-bold-hovered, #0055cc);
$ds-background-brand-bold-pressed: var(--ds-background-brand-bold-pressed, #09326c);
$ds-background-danger: var(--ds-background-danger, #ffedeb);
$ds-background-danger-hovered: var(--ds-background-danger-hovered, #ffd2cc);
$ds-background-danger-pressed: var(--ds-background-danger-pressed, #ff9c8f);
$ds-background-danger-bold: var(--ds-background-danger-bold, #ca3521);
$ds-background-danger-bold-hovered: var(--ds-background-danger-bold-hovered, #ae2a19);
$ds-background-danger-bold-pressed: var(--ds-background-danger-bold-pressed, #601e16);
$ds-background-warning: var(--ds-background-warning, #fff7d6);
$ds-background-warning-hovered: var(--ds-background-warning-hovered, #f8e6a0);
$ds-background-warning-pressed: var(--ds-background-warning-pressed, #f5cd47);
$ds-background-warning-bold: var(--ds-background-warning-bold, #e2b203);
$ds-background-warning-bold-hovered: var(--ds-background-warning-bold-hovered, #cf9f02);
$ds-background-warning-bold-pressed: var(--ds-background-warning-bold-pressed, #b38600);
$ds-background-success: var(--ds-background-success, #dffcf0);
$ds-background-success-hovered: var(--ds-background-success-hovered, #baf3db);
$ds-background-success-pressed: var(--ds-background-success-pressed, #7ee2b8);
$ds-background-success-bold: var(--ds-background-success-bold, #1f845a);
$ds-background-success-bold-hovered: var(--ds-background-success-bold-hovered, #216e4e);
$ds-background-success-bold-pressed: var(--ds-background-success-bold-pressed, #164b35);
$ds-background-discovery: var(--ds-background-discovery, #f3f0ff);
$ds-background-discovery-hovered: var(--ds-background-discovery-hovered, #dfd8fd);
$ds-background-discovery-pressed: var(--ds-background-discovery-pressed, #b8acf6);
$ds-background-discovery-bold: var(--ds-background-discovery-bold, #6e5dc6);
$ds-background-discovery-bold-hovered: var(--ds-background-discovery-bold-hovered, #5e4db2);
$ds-background-discovery-bold-pressed: var(--ds-background-discovery-bold-pressed, #352c63);
$ds-background-information: var(--ds-background-information, #e9f2ff);
$ds-background-information-hovered: var(--ds-background-information-hovered, #cce0ff);
$ds-background-information-pressed: var(--ds-background-information-pressed, #85b8ff);
$ds-background-information-bold: var(--ds-background-information-bold, #0c66e4);
$ds-background-information-bold-hovered: var(--ds-background-information-bold-hovered, #0055cc);
$ds-background-information-bold-pressed: var(--ds-background-information-bold-pressed, #09326c);
$ds-blanket: var(--ds-blanket, #091e427d);
$ds-blanket-selected: var(--ds-blanket-selected, #388bff14);
$ds-blanket-danger: var(--ds-blanket-danger, #ef5c4814);
$ds-interaction-hovered: var(--ds-interaction-hovered, #00000029);
$ds-interaction-pressed: var(--ds-interaction-pressed, #00000052);
$ds-skeleton: var(--ds-skeleton, #091e420f);
$ds-skeleton-subtle: var(--ds-skeleton-subtle, #091e4208);
$ds-chart-categorical-1: var(--ds-chart-categorical-1, #1d9aaa);
$ds-chart-categorical-1-hovered: var(--ds-chart-categorical-1-hovered, #1d7f8c);
$ds-chart-categorical-2: var(--ds-chart-categorical-2, #5e4db2);
$ds-chart-categorical-2-hovered: var(--ds-chart-categorical-2-hovered, #352c63);
$ds-chart-categorical-3: var(--ds-chart-categorical-3, #d97008);
$ds-chart-categorical-3-hovered: var(--ds-chart-categorical-3-hovered, #b65c02);
$ds-chart-categorical-4: var(--ds-chart-categorical-4, #943d73);
$ds-chart-categorical-4-hovered: var(--ds-chart-categorical-4-hovered, #50253f);
$ds-chart-categorical-5: var(--ds-chart-categorical-5, #09326c);
$ds-chart-categorical-5-hovered: var(--ds-chart-categorical-5-hovered, #082145);
$ds-chart-categorical-6: var(--ds-chart-categorical-6, #8f7ee7);
$ds-chart-categorical-6-hovered: var(--ds-chart-categorical-6-hovered, #8270db);
$ds-chart-categorical-7: var(--ds-chart-categorical-7, #50253f);
$ds-chart-categorical-7-hovered: var(--ds-chart-categorical-7-hovered, #341829);
$ds-chart-categorical-8: var(--ds-chart-categorical-8, #974f0c);
$ds-chart-categorical-8-hovered: var(--ds-chart-categorical-8-hovered, #5f3811);
$ds-chart-neutral: var(--ds-chart-neutral, #8590a2);
$ds-chart-neutral-hovered: var(--ds-chart-neutral-hovered, #758195);
$ds-chart-red-bold: var(--ds-chart-red-bold, #ef5c48);
$ds-chart-red-bold-hovered: var(--ds-chart-red-bold-hovered, #e34935);
$ds-chart-red-bolder: var(--ds-chart-red-bolder, #e34935);
$ds-chart-red-bolder-hovered: var(--ds-chart-red-bolder-hovered, #ca3521);
$ds-chart-red-boldest: var(--ds-chart-red-boldest, #ae2a19);
$ds-chart-red-boldest-hovered: var(--ds-chart-red-boldest-hovered, #601e16);
$ds-chart-orange-bold: var(--ds-chart-orange-bold, #d97008);
$ds-chart-orange-bold-hovered: var(--ds-chart-orange-bold-hovered, #b65c02);
$ds-chart-orange-bolder: var(--ds-chart-orange-bolder, #b65c02);
$ds-chart-orange-bolder-hovered: var(--ds-chart-orange-bolder-hovered, #974f0c);
$ds-chart-orange-boldest: var(--ds-chart-orange-boldest, #974f0c);
$ds-chart-orange-boldest-hovered: var(--ds-chart-orange-boldest-hovered, #5f3811);
$ds-chart-yellow-bold: var(--ds-chart-yellow-bold, #b38600);
$ds-chart-yellow-bold-hovered: var(--ds-chart-yellow-bold-hovered, #946f00);
$ds-chart-yellow-bolder: var(--ds-chart-yellow-bolder, #946f00);
$ds-chart-yellow-bolder-hovered: var(--ds-chart-yellow-bolder-hovered, #7f5f01);
$ds-chart-yellow-boldest: var(--ds-chart-yellow-boldest, #7f5f01);
$ds-chart-yellow-boldest-hovered: var(--ds-chart-yellow-boldest-hovered, #533f04);
$ds-chart-green-bold: var(--ds-chart-green-bold, #22a06b);
$ds-chart-green-bold-hovered: var(--ds-chart-green-bold-hovered, #1f845a);
$ds-chart-green-bolder: var(--ds-chart-green-bolder, #1f845a);
$ds-chart-green-bolder-hovered: var(--ds-chart-green-bolder-hovered, #216e4e);
$ds-chart-green-boldest: var(--ds-chart-green-boldest, #216e4e);
$ds-chart-green-boldest-hovered: var(--ds-chart-green-boldest-hovered, #164b35);
$ds-chart-teal-bold: var(--ds-chart-teal-bold, #1d9aaa);
$ds-chart-teal-bold-hovered: var(--ds-chart-teal-bold-hovered, #1d7f8c);
$ds-chart-teal-bolder: var(--ds-chart-teal-bolder, #1d7f8c);
$ds-chart-teal-bolder-hovered: var(--ds-chart-teal-bolder-hovered, #206b74);
$ds-chart-teal-boldest: var(--ds-chart-teal-boldest, #206b74);
$ds-chart-teal-boldest-hovered: var(--ds-chart-teal-boldest-hovered, #1d474c);
$ds-chart-blue-bold: var(--ds-chart-blue-bold, #388bff);
$ds-chart-blue-bold-hovered: var(--ds-chart-blue-bold-hovered, #1d7afc);
$ds-chart-blue-bolder: var(--ds-chart-blue-bolder, #1d7afc);
$ds-chart-blue-bolder-hovered: var(--ds-chart-blue-bolder-hovered, #0c66e4);
$ds-chart-blue-boldest: var(--ds-chart-blue-boldest, #0055cc);
$ds-chart-blue-boldest-hovered: var(--ds-chart-blue-boldest-hovered, #09326c);
$ds-chart-purple-bold: var(--ds-chart-purple-bold, #8f7ee7);
$ds-chart-purple-bold-hovered: var(--ds-chart-purple-bold-hovered, #8270db);
$ds-chart-purple-bolder: var(--ds-chart-purple-bolder, #8270db);
$ds-chart-purple-bolder-hovered: var(--ds-chart-purple-bolder-hovered, #6e5dc6);
$ds-chart-purple-boldest: var(--ds-chart-purple-boldest, #5e4db2);
$ds-chart-purple-boldest-hovered: var(--ds-chart-purple-boldest-hovered, #352c63);
$ds-chart-magenta-bold: var(--ds-chart-magenta-bold, #da62ac);
$ds-chart-magenta-bold-hovered: var(--ds-chart-magenta-bold-hovered, #cd519d);
$ds-chart-magenta-bolder: var(--ds-chart-magenta-bolder, #cd519d);
$ds-chart-magenta-bolder-hovered: var(--ds-chart-magenta-bolder-hovered, #ae4787);
$ds-chart-magenta-boldest: var(--ds-chart-magenta-boldest, #943d73);
$ds-chart-magenta-boldest-hovered: var(--ds-chart-magenta-boldest-hovered, #50253f);
$ds-chart-gray-bold: var(--ds-chart-gray-bold, #8590a2);
$ds-chart-gray-bold-hovered: var(--ds-chart-gray-bold-hovered, #758195);
$ds-chart-gray-bolder: var(--ds-chart-gray-bolder, #758195);
$ds-chart-gray-bolder-hovered: var(--ds-chart-gray-bolder-hovered, #626f86);
$ds-chart-gray-boldest: var(--ds-chart-gray-boldest, #44546f);
$ds-chart-gray-boldest-hovered: var(--ds-chart-gray-boldest-hovered, #2c3e5d);
$ds-chart-brand: var(--ds-chart-brand, #1d7afc);
$ds-chart-brand-hovered: var(--ds-chart-brand-hovered, #0c66e4);
$ds-chart-danger: var(--ds-chart-danger, #ef5c48);
$ds-chart-danger-hovered: var(--ds-chart-danger-hovered, #e34935);
$ds-chart-danger-bold: var(--ds-chart-danger-bold, #ae2a19);
$ds-chart-danger-bold-hovered: var(--ds-chart-danger-bold-hovered, #601e16);
$ds-chart-warning: var(--ds-chart-warning, #b38600);
$ds-chart-warning-hovered: var(--ds-chart-warning-hovered, #946f00);
$ds-chart-warning-bold: var(--ds-chart-warning-bold, #7f5f01);
$ds-chart-warning-bold-hovered: var(--ds-chart-warning-bold-hovered, #533f04);
$ds-chart-success: var(--ds-chart-success, #22a06b);
$ds-chart-success-hovered: var(--ds-chart-success-hovered, #1f845a);
$ds-chart-success-bold: var(--ds-chart-success-bold, #216e4e);
$ds-chart-success-bold-hovered: var(--ds-chart-success-bold-hovered, #164b35);
$ds-chart-discovery: var(--ds-chart-discovery, #8f7ee7);
$ds-chart-discovery-hovered: var(--ds-chart-discovery-hovered, #8270db);
$ds-chart-discovery-bold: var(--ds-chart-discovery-bold, #5e4db2);
$ds-chart-discovery-bold-hovered: var(--ds-chart-discovery-bold-hovered, #352c63);
$ds-chart-information: var(--ds-chart-information, #388bff);
$ds-chart-information-hovered: var(--ds-chart-information-hovered, #1d7afc);
$ds-chart-information-bold: var(--ds-chart-information-bold, #0055cc);
$ds-chart-information-bold-hovered: var(--ds-chart-information-bold-hovered, #09326c);
$ds-surface: var(--ds-surface, #ffffff);
$ds-surface-hovered: var(--ds-surface-hovered, #f1f2f4);
$ds-surface-pressed: var(--ds-surface-pressed, #dcdfe4);
$ds-surface-overlay: var(--ds-surface-overlay, #ffffff);
$ds-surface-overlay-hovered: var(--ds-surface-overlay-hovered, #f1f2f4);
$ds-surface-overlay-pressed: var(--ds-surface-overlay-pressed, #dcdfe4);
$ds-surface-raised: var(--ds-surface-raised, #ffffff);
$ds-surface-raised-hovered: var(--ds-surface-raised-hovered, #f1f2f4);
$ds-surface-raised-pressed: var(--ds-surface-raised-pressed, #dcdfe4);
$ds-surface-sunken: var(--ds-surface-sunken, #f7f8f9);
$ds-shadow-overflow-perimeter: var(--ds-shadow-overflow-perimeter, #091e421f);
$ds-shadow-overflow-spread: var(--ds-shadow-overflow-spread, #091e4229);
$ds-opacity-disabled: var(--ds-opacity-disabled, 0.4);
$ds-opacity-loading: var(--ds-opacity-loading, 0.2);

$ds-shadow-overflow: var(--ds-shadow-overflow, 0px 0px 8px #091e4229, 0px 0px 1px #091e421f);
$ds-shadow-overlay: var(--ds-shadow-overlay, 0px 8px 12px #091e4226, 0px 0px 1px #091e424f);
$ds-shadow-raised: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);

// Add Trello extras
$tr-background-accent-lime-subtlest: var(--tr-background-accent-lime-subtlest, #eefbda);
$tr-background-accent-lime-subtler: var(--tr-background-accent-lime-subtler, #d3f1a7);
$tr-background-accent-lime-subtle: var(--tr-background-accent-lime-subtle, #94c748);
$tr-background-accent-lime-bolder: var(--tr-background-accent-lime-bolder, #5b7f24);
$tr-border-accent-lime: var(--tr-border-accent-lime, #6a9a23);
$tr-icon-accent-lime: var(--tr-icon-accent-lime, #6a9a23);
$tr-text-accent-lime: var(--tr-text-accent-lime, #4c6b1f);
$tr-text-accent-lime-bolder: var(--tr-text-accent-lime-bolder, #37471f);
